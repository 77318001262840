import { default as colors } from "/styles/colors";
import { DODGER_BLUE_COLOR_NAME, SPACE_BLUE_COLOR_NAME, WHITE_COLOR_NAME, IRIS_COLOR_NAME, BLUE_COLOR_NAME, BRAND_BLACK_TEXT_COLOR } from "styles/color-consts";
export default {
    [BLUE_COLOR_NAME]: {
        "background-color": colors["brand-blue"],
        color: colors["white"],
        "disabled-background-color": colors["blue-hover"]
    },
    "outline-blue": {
        "background-color": colors["white"],
        color: colors["light-blue"],
        "disabled-background-color": colors["white"]
    },
    "outline-dark-blue": {
        "background-color": colors["white"],
        color: colors["brand-dark-blue"],
        "hover-color": colors["white"],
        "disabled-background-color": colors["brand-dark-blue"]
    },
    "outline-indigo": {
        "background-color": colors["white"],
        color: colors["indigo"],
        "hover-color": colors["white"],
        "disabled-background-color": colors["indigo"]
    },
    "brand-pink": {
        "background-color": colors["signup-pink"],
        color: colors["white"],
        "disabled-background-color": colors["signup-pink-hover"]
    },
    "brand-blue": {
        "background-color": colors["brand-blue"],
        color: colors["white"],
        "disabled-background-color": colors["blue-hover"]
    },
    "brand-red": {
        "background-color": colors["brand-red"],
        color: colors["white"],
        "disabled-background-color": colors["brand-red-hover"]
    },
    "brand-green": {
        "background-color": colors["brand-green"],
        color: colors["white"],
        "disabled-background-color": colors["brand-green-hover"]
    },
    "brand-yellow": {
        "background-color": colors["brand-yellow"],
        color: colors["white"],
        "disabled-background-color": colors["brand-yellow-hover"]
    },
    "brand-iris": {
        "background-color": colors["brand-iris"],
        color: colors["white"],
        "disabled-background-color": colors["brand-iris-hover"]
    },
    "brand-purple": {
        "background-color": colors["brand-purple"],
        color: colors["white"],
        "disabled-background-color": colors["brand-purple-hover"]
    },
    //new themes
    green: {
        "background-color": colors["green"],
        color: colors["white"],
        "disabled-background-color": colors["green-hover"]
    },
    purple: {
        "background-color": colors["purple"],
        color: colors["white"],
        "disabled-background-color": colors["brand-purple-hover"]
    },
    [IRIS_COLOR_NAME]: {
        "background-color": colors["iris"],
        color: colors["white"],
        "disabled-background-color": colors["iris-hover"]
    },
    yellow: {
        "background-color": colors["yellow"],
        color: colors["mud"],
        "disabled-background-color": colors["brand-yellow-hover"]
    },
    orange: {
        "background-color": colors["orange"],
        color: colors["mud"],
        "disabled-background-color": colors["orange"]
    },
    pink: {
        "background-color": colors["pink"],
        color: colors["white"],
        "disabled-background-color": colors["pink-hover"]
    },
    royal: {
        "background-color": colors["royal"],
        color: colors["white"],
        "disabled-background-color": colors["royal-hover"]
    },
    [DODGER_BLUE_COLOR_NAME]: {
        "background-color": colors["dodger-blue"],
        color: colors["white"],
        "disabled-background-color": colors["dodger-blue-hover"]
    },
    [SPACE_BLUE_COLOR_NAME]: {
        "background-color": colors[SPACE_BLUE_COLOR_NAME],
        color: colors.white,
        "disabled-background-color": colors["space-blue-hover"]
    },
    [WHITE_COLOR_NAME]: {
        "background-color": colors["white"],
        color: colors[BRAND_BLACK_TEXT_COLOR],
        "disabled-background-color": colors["white"]
    },
    [BRAND_BLACK_TEXT_COLOR]: {
        "background-color": colors[BRAND_BLACK_TEXT_COLOR],
        color: colors["white"],
        "disabled-background-color": colors[BRAND_BLACK_TEXT_COLOR]
    }
};

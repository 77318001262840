import styled from "@emotion/styled";
import { getHoverColor } from "styles/colors-utils";
import colors from "styles/colors";
import { BACKGROUND_GRAY_COLOR_NAME } from "styles/color-consts";
import { colorsStyles } from "./styles/colors-styles.scss";
import { fontsStyles } from "./styles/fonts-styles.scss";
import laddaStyles from "./button-ladda-component.scss";
export const StyledButtonComponent = styled.div`
  ${({ buttonColor })=>`
      button.ladda-button[data-button-color="${buttonColor}"] {
        &:not(.invert) {
          transition: background-color 200ms ease;
          &:hover {
            background-color: ${getHoverColor(colors[buttonColor])};
            filter: unset;
            &.white,
            &.white-iris {
              background-color: ${colors[BACKGROUND_GRAY_COLOR_NAME]};
            }
          }
        }
      }
  `}

  &.center {
    text-align: center;
  }

  &.left {
    align-self: flex-start;
  }

  ${colorsStyles}
  ${laddaStyles}

  button.ladda-button {
    padding: 16px 32px;
    font-size: 1rem;
    border-radius: 40px;
    border: none;
    font-weight: 400;
    text-align: center;
    cursor: pointer;
    user-select: none;
    line-height: 17px;

    &[data-style="zoom-in"] {
      &:not(:hover) {
        transition: 0.1s ease background-color;
      }
    }

    &.disable-animation {
      &.ladda-button[data-style="zoom-out"] .ladda-label {
        transition: none !important;
        z-index: 0;
      }

      &.ladda-button .ladda-label {
        transition: none !important;
        z-index: 0;
      }
    }

    &.has-arrow {
      padding: 16px 32px 16px 31px;
      &.sm {
        padding-left: 29px;
      }
    }

    &.invert {
      border-width: 1px;
      border-style: solid;
    }

    ${fontsStyles}
    
    &.has-paint-animation {
      isolation: isolate; // https://stackoverflow.com/a/58283449
    }

    &:disabled {
      cursor: auto;
    }

    &:hover {
      outline: none;
    }

    img {
      vertical-align: middle;
    }

    .paint-overlay {
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      border-radius: 50%;
      background-color: var(--signup-paint-animation-color);
      transform: translate(-50%, -50%);
      z-index: -1;
      top: 8px;
      left: calc(100% / 2);
      &.start-animating {
        animation: paintAnimation 1s ease-in-out;
      }
    }
    

    @keyframes paintAnimation {
      0% {
        width: 0;
        height: 0;
      }
      60% {
        width: ${`${180 * 2.25}px`};
        height: ${`${180 * 2.25}px`};
      }
      100% {
        opacity: 0;
      }
    }
  }
`;

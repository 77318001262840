import styled from "@emotion/styled";
import { TABLET_QUERY_MAX_WIDTH } from "/constants/vp-sizes";
export const StyledWithErrorComponent = styled.div`
    position: relative;
    width: 100%;

    .error-tooltip-text {
      color: red;
      font-weight: 300;
    }

    .tooltip {
      transform: translate(0, -120%);
    }

    @media (max-width: ${TABLET_QUERY_MAX_WIDTH}) {
      .tooltip {
        transform: translate(5%, -120%);
      }
  }
`;

import styled from "@emotion/styled";
import colors from "styles/colors";
import { MODAL_BACKGROUND } from "constants/z-index";
const darkColor = colors.mud;
const lightColor = colors.white;
const SIZE = "12px";
export const StyledTooltipComponent = styled.div`
  font-size: 0.8125rem;
  border-radius: 8px;
  padding: 14px;
  z-index: ${MODAL_BACKGROUND - 1};
  white-space: nowrap;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  position: absolute;

  &.light {
    background-color: ${lightColor};
    color: ${darkColor};

    &:after {
      border-top-color: ${lightColor};
    }
  }

  &.dark {
    background-color: ${darkColor};
    color: ${lightColor};

    &:after {
      border-top-color: ${darkColor};
    }
  }

  &:after {
    content: "";
    position: absolute;
    left: calc(50% - ${SIZE} / 3);
    width: 0;
    height: 0;
    bottom: calc(1px - ${SIZE});
    border-style: solid;
    border-width: ${SIZE} ${SIZE} 0 ${SIZE};
    border-color: transparent;
  }
`;

import { SIGNUP_REFERRER } from "constants/localstorage";
export const updateReferrerBeforeSignup = ()=>{
    const referrer = document.referrer;
    // don't store referrer if it exists already
    if (localStorage.getItem(SIGNUP_REFERRER)) return;
    localStorage.setItem(SIGNUP_REFERRER, JSON.stringify({
        value: referrer,
        timestamp: Date.now()
    }));
};
